import '../App.css';
import Gap from './Gap.js';
import { useInView } from 'react-hook-inview'
import { useState } from 'react';

function Story(props) {
  
  const [state, setState] = useState(false)
  const [ref, isVisible] = useInView({
    threshold: 1,
    rootMargin: '0px 0px 0px 400px',
    onEnter: function(el) {

      let triciclo = document.querySelector("#triciclo-gif");
      let imgNodeClass = el.target.lastElementChild.childNodes[0].className == "story-image--end-image--bottom-right-50 ";
      console.log(imgNodeClass);
        
    
      if(imgNodeClass) {
        let gtmEvent = props.gtmEvent;

      if(gtmEvent) {
        console.log('gtm -> ' + gtmEvent);
         window.dataLayer.push({
          event: 'timeline_end_success'
          
        });
      }

        if(window.matchMedia('(max-width: 991px)').matches) {
          
          triciclo.classList.add('opacity-0');
        }

      }
      else {
        triciclo.classList.remove('opacity-0');
      }

    }
  },[state])

  


  return (
    <div class="test">
      <div id="story" ref={ref} className={isVisible ? 'step story timeline-step timeline-step--visible' : 'step story timeline-step'}>
        {props.photo  && <div className="gap-item--image-container">
          <img id="50-years-img" src={props.photo} className={ "story-image--" + props.alignment + " " + props.type} alt=""/>
          
          </div> }
          {props.textYear && <p className={props.storyTextType}>{props.textYear}</p>}
          {props.textDescription && <p className={ props.textSmallType }>{props.textDescription}</p>}
          
      </div>
      <Gap gapPhoto={props.gapPhoto} gapSize={props.gapSize} alignment={props.gapAlignment} />
    </div>
    
   
  );
}

export default Story;
