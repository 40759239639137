import '../App.css';
import Gap from './Gap.js';
import { useInView } from 'react-hook-inview'
import { useState } from 'react';

function Garage(props) {
  




  

  return (
    <div>
      <div className='garage-2 step story timeline-step timeline-step--visible'>
        {props.photo  && <div className="gap-item--image-container">
          <img src={props.photo} className={ "story-image--" + props.alignment + " " + props.type} alt=""/></div> }
      </div>
      <Gap gapPhoto={props.gapPhoto} gapSize={props.gapSize} alignment={props.gapAlignment} />
    </div>
    
   
  );
}

export default Garage;