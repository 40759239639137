import '../../App.css';
import { useState } from 'react';

function Services(props) {

  return (
    <div>
         <section id="services" className="m-t-5 m-b-5 p-t-4 p-b-4" >
        <div className="wrapper--large">
            <div className="d-flex justify-content-center" data-scrolled-into-view="true">
                <h2>Our services</h2>
            </div>

            <div className="d-flex justify-content-around flex-wrap p-3">
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_pencil_rev.png" width="100%"/> 
                    </div>
                    
                    <h4 className="bold-black text-center">Brand design</h4>
                    <p className="grey text-center">Logo design, packaging, signage, ambient design.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_strategy.png" width="100%"/> 
                </div>
                   
                    <h4 className="bold-black text-center">Strategic planning</h4>
                    <p className="grey text-center">Market analysis, consumer studies, strategic consulting.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_eye.png" width="100%"/> 
                    </div>
                    
                    <h4 className="bold-black text-center">Creative concept development</h4>
                    <p className="grey text-center">Brand and product campaigns, retail studio, print production.</p>
                </div>        
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_target.png" width="100%"/> 
                    </div>
                    <h4 className="bold-black text-center">Media services</h4>
                    <p className="grey text-center">Competitive monitoring, planning and purchasing, latest technology.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_digital.png" width="100%"/> 
                    </div>
                    
                    <h4 className="bold-black text-center">Digital strategies</h4>
                    <p className="grey text-center">Web design, online advertising, mobile apps.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_film.png" width="100%"/> 
                    </div>
                  
                    <h4 className="bold-black text-center">Production</h4>
                    <p className="grey text-center">Video and Audio production, post-production editing.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_social.png" width="100%"/> 
                    </div>
                    
                    <h4 className="bold-black text-center">Social media</h4>
                    <p className="grey text-center">Brand engagement and monitoring.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_events.png" width="100%"/> 
                    </div>
                    
                    <h4 className="bold-black text-center">Events + promotions</h4>
                    <p className="grey text-center">Licence to produce public events, promotions, trade shows, seminars, concerts.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img className="p-3" src="images/icons/icon_relations.png" width="100%"/> 
                    </div>
                   
                    <h4 className="bold-black text-center">Public relations</h4>
                    <p className="grey text-center">Corporate image, crisis management, community programs.</p>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Services;


