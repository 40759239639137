import '../../App.css';
import { useState } from 'react';

function Footer(props) {

  return (
    <div>
          <footer id="footer" className="footer">
        <div class="container">
            <div class="d-flex justify-content-center">
                <p class="p-white p-5 t-center">© <span class="year-date"></span>. Lopito, Ileana & Howie, Inc. All rights reserved.</p>
            </div>
            <div class="d-flex justify-content-center">
                <a href="https://www.facebook.com/lihadvertising/" target="_blank" rel="nofollow">
                <img class="pe-3" src="images/icons/fb_icon.png" />
                </a>
                <a href="https://twitter.com/lihadvertising" target="_blank" rel="nofollow">
                <img class="pe-3" src="images/icons/twitter_icon.png" />
                </a>
                <a href="https://www.linkedin.com/company/lopito-ileana-&-howie" target="_blank" rel="nofollow">
                <img class="pe-3" src="images/icons/linkedin_icon.png" />
                </a>
                <a href="https://vimeo.com/lihpr" target="_blank" rel="nofollow">
                <img class="pe-3" src="images/icons/yt_icon.png" />
                </a>
                <a href="mailto:hello@lih.com" target="_blank" rel="nofollow">
                <img class="" src="images/icons/email_icon.png" />
                </a>
            </div>
        </div>
    </footer>
    </div>
  );
}

export default Footer;


