function Timelineimage(props) {

  function handleClick(e) {
    e.preventDefault();
    console.log('sdf');
    let timelineImg = document.getElementById('timeline-img');
    let closeBtn = document.getElementById('close-btn');
    let timelineParent = document.getElementById('timeline-parent');
    let tricicloGif = document.getElementById('triciclo-gif');
    setInterval(function () {tricicloGif.classList.add("triciclo-animation")}, 200);
    timelineImg.classList.add("display-none");
    timelineParent.classList.add("display--opacity-1");
    closeBtn.classList.add("display");

    let header = document.getElementById('headerTag');
    let mt = document.getElementById('mt-44');
    let about = document.getElementById('about');
    let work = document.getElementById('work');
    let awards = document.getElementById('awards');
    let clients = document.getElementById('clients-sec');
    let icomBanner = document.getElementById('icom-banner');
    let services = document.getElementById('services');
    let lihNews = document.getElementById('lih-news');
    let contact = document.getElementById('contacts');
    let footer = document.getElementById('footer');
    let body = document.getElementById('body-color');
    // let closeBtn = document.getElementById('close-btn');
    body.classList.add("bg-grey");
    header.classList.add("display-none");
    mt.classList.add("display-none");
    about.classList.add("display-none");
    work.classList.add("display-none");
    awards.classList.add("display-none");
    clients.classList.add("display-none");
    icomBanner.classList.add("display-none");
    services.classList.add("display-none");
    lihNews.classList.add("display-none");
    contact.classList.add("display-none");
    footer.classList.add("display-none");
    // closeBtn.classList.add("display");
    
  }
  return (
    
    //   <section id="timeline" className="timeline-section m-b-5 bg-dark-blue">
    //     <div className="d-flex justify-content-center">
    //         <div className="m-t-80">
    //         <a href="#" onClick={handleClick}><img className="timeline-btn p-4 img-responsive" src="images/timeline-icon-lrg.png" title="" alt=""/></a>
    //         </div>
    //     </div>
    // </section>

    <section id="timeline-img" className="bg-dark-blue p-5">
        
           
        <div className="wrap-timeline-img d-flex justify-content-evenly align-items-center flex-direction--col-mob-only">
            <img className="m-1" src="../images/logo-lih-o.png" />
            <p className="text-white m-3" style={{fontSize: "30px", margin: "0px", letterSpacing: "8px", fontFamily: "brandingmedium"}}>50 YEARS OF GREAT IDEAS</p>
            <a href="#nose" onClick={handleClick}>
              <button className="btn-orange m-3 branding-font--bold p-0">See our timeline</button>
            </a>
</div>
        
      
</section>
    
    
    
  );
}

export default Timelineimage;