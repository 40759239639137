import '../../App.css';
import { useState } from 'react';

function VideoSection(props) {

  return (
    <section id="mt-44" className="mt-44">
      
        <div className="header-container--video">
            <div className="video-box">

            </div>
            <img className="lih-logo mobile-hide--1200" src="images/logo-lih-w.png" title="" alt="" />
            <h1 className="header-caption--h1 t-center">50 Years<br className="hide-620" /> Forward</h1>
            <p className="header-caption--p subtitle-video t-center">Lopito, Ileana & Howie celebrates<br className="hide-620" /> five decades of building brands<br className="hide-620" /> and influencing culture.</p>
            <a className="cta-btn--video t-center" href="https://player.vimeo.com/video/687225223?h=118c4ec405" data-lity>Play video</a>
        </div> 
        <p class="loader-text">Loading...</p>
    </section>
  );
}

export default VideoSection;
