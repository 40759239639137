import '../../App.css';
import { useState } from 'react';

function Contact(props) {

  return (
    <div>
        <section id="contacts" className="m-t-5 m-b-5 p-t-4 p-b-4">
        <div className="wrapper--small">
            <div className="d-flex flex-column align-items-center" data-scrolled-into-view="true">
                <h2 className="m-b-3">Contact us</h2>
                <p className="grey text-center ps-4 pe-4">Want to get started? Send us a message below or give us<br className="hide-620"/>
                    a good ol’ fashioned phone call at <a className="orange-link" href="tel:17877831160">1.787.783.1160</a>.</p>
            </div>
            <div className="d-flex justify-content-center">
            <form className="p-4 form-style" action="index.php" method="post" id="contact_form_submit">
                                        
                                        <div className="d-flex justify-content-between column-991" data-scrolled-into-view="true">

                                            <div className="col-47 full-width--mobile-only">
                                                <label htmlFor="input-name" className="grey">Name</label>
                                                <input type="text" id="name" name="name" className="name-input" placeholder=""/>

                                            </div>
                                            <div className="col-47 full-width--mobile-only">
                                                <label htmlFor="input-lastname" className="grey">Last name</label>
                                                <input type="text" id="lastname" name="lastname" className="lastname-input" placeholder=""/>
                                            </div>

                                        </div>
                                        <label htmlFor="adress" className="grey" data-scrolled-into-view="true">Email</label>
                                        <input type="email" id="email" name="email" className="email-input" placeholder="" data-scrolled-into-view="true"/>
                                        
                                        <label htmlFor="message" className="grey" data-scrolled-into-view="true">Message</label>
                                        <textarea id="message" name="message" className="message-input" placeholder="" data-scrolled-into-view="true"></textarea>
                                        <div className="d-flex justify-content-center mt-5" data-scrolled-into-view="true">
                                            <button type="submit" value="Submit" className="btn-orange text-center"><span>Submit</span></button>
                                        </div>

             </form>
            </div>            
        </div>
    </section>
    </div>
  );
}

export default Contact;


