import '../../App.css';
import { useState } from 'react';

function Awards(props) {

  return (
    <div>
         <section id="awards" className="section-bg--grey m-b-4">
        <div className="wrapper--fullwidth p-t-100 p-b-100">
            <div className="d-flex justify-content-center" data-scrolled-into-view="true">
                <h2 className="p-b-3--desktop-only text-center awards-text"></h2>
            </div>
            <div className="awards-row d-flex justify-content-around wrap-991 p-3" data-scrolled-into-view="true">
                <div className="cannes-logo icon-container--awards">
                    <img src="images/logos/logo_cannes_150.png" width="100%"/>
                </div>
                <div className="excel-logo icon-container--awards">
                    <img src="images/logos/logo_excel_150.png" width="100%"/>
                </div>
                <div className="cma-logo icon-container--awards">
                    <img src="images/logos/logo_cma_150.png" />
                </div>
                <div className="zero-logo icon-container--awards">
                    <img src="images/logos/logo_zero_150.png" />
                </div>
                <div className="sme-logo icon-container--awards">
                    <img src="images/logos/logo_sme_150.png" />
                </div>
                <div className="cospide-logo icon-container--awards">
                    <img src="images/logos/logo_cospide_150.png" />
                </div>
                <div className="ojo-logo icon-container--awards">
                    <img src="images/logos/logo_ojo_150.png" />
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Awards;


