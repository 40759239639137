import '../../App.css';


function Work(props) {

  return (
    <div>
      
         <section id="work" className="section">
                <div className="row portfolio-container five-per-row" data-scrolled-into-view="true">

                    <div className="portfolio-item" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/925050863" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                               
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>ORIENTAL</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>PARRANDA SI PUEDO</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/lih-2024-projectos--Oriental-parranda-si-puedo.jpeg" alt="portfolio item" className="featured-image-v2" style={{outline: "none", cursor: "inherit"}}/></figure>
                            </header>
                        </a>
                    </div>

                    <div className="portfolio-item width-2x" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/925044216" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                               
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>Sociedad Americana Contra el Cáncer</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>CUIDEMOS LAS...</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/lih-2024-projectos--SACC-CuidemosLasTetas.jpeg" alt="portfolio item 2" className="featured-image-v2 darken" style={{outline: "none", cursor: "inherit"}}/></figure>
                            </header>
                        </a>
                    </div>
                    <div className="portfolio-item width-2x" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/925125138" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                             
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>Universal</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>TEAM RUBIO</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/lih-2024-projectos--Universal-team-rubio.jpeg" alt="portfolio item 3" className="featured-image-v2 darken" style={{outline: "none", cursor: "inherit"}} /></figure>
                            </header>
                        </a>
                    </div>
                    
                    <div className="portfolio-item width-2x">
                        <a href="https://vimeo.com/925049402" data-lity>
                            <div className="portfolio-meta">
                            
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>UNIVERSAL</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>AÑADE UNA SILLA</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/lih-2024-projectos--Universal-anade-una-silla.jpeg" alt="portfolio item 4" className="featured-image-v2 darken"/></figure>
                            </header>
                        </a>
                    </div>
                    <div className="portfolio-item" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/722614974" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                               
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>Puerto Rico Queer Fest</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>Tenemos que hablar</h3>
                                </div>
                                <figure className="portfolio-img v-1"><img src="images/project-img5-covito.jpg" alt="portfolio item 5" className="featured-image-v2" style={{outline: "none", cursor: "inherit"}} /></figure>
                            </header>
                        </a>
                    </div>
                    <div className="portfolio-item width-2x">
                        <a href="https://vimeo.com/925122707" data-lity>
                            <div className="portfolio-meta">
                                    <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                    <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                                </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>GE APPLIANCES</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>DE PUEBLO A TU CASA CON GE APPLIANCES</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/lih-2024-projectos--GE-APPLIANCES-de-pueblo-a-tu-casa.jpeg" alt="portfolio item 6" className="featured-image-v2 darken" /></figure>
                            </header>
                        </a>
                    </div>

                    {/* <div className="portfolio-item width-2x" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/706745748" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                               
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>Universal</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>Que suene para todos</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/project-img7.jpg" alt="portfolio item 7" className="featured-image-v2" style={{outline: "none", cursor: "inherit"}} /></figure>
                            </header>
                        </a>
                    </div> */}

                    <div className="portfolio-item width-2x" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/925047925" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                               
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>DirectTV</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>LO VISTE EN VIVO</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/lih-2024-projectos--DIRECTTV-lo-viste-en-vivo.jpeg" alt="portfolio item 5" className="featured-image-v2 darken" style={{outline: "none", cursor: "inherit"}} /></figure>
                            </header>
                        </a>
                    </div>
                    <div className="portfolio-item" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/718500716" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                               
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>Nissan</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>Emoción</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/project-img10.jpg" alt="portfolio item 7" className="featured-image" style={{outline: "none", cursor: "inherit"}} /></figure>
                            </header>
                        </a>
                    </div>
                    <div className="portfolio-item width-2x">
                        <a href="https://vimeo.com/720421471" data-lity>
                            <div className="portfolio-meta">
                                  
                                    <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                    <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                                </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>JetBlue</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>Jetscusas</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/project-img9.jpg" alt="portfolio item 6" className="featured-image" /></figure>
                            </header>
                        </a>
                    </div>
                    

                    <div className="portfolio-item width-2x" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/706745585" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                             
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>MCS</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>Eres tú</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/project-img3.jpg" alt="portfolio item 3" className="featured-image-v2" style={{outline: "none", cursor: "inherit"}} /></figure>
                            </header>
                        </a>
                    </div>
                    <div className="portfolio-item width-3x" style={{outline: "none", cursor: "inherit"}}>
                        <a href="https://vimeo.com/706745134" style={{outline: "none", cursor: "inherit"}} data-lity>
                            <div className="portfolio-meta">
                               
                                <time datetime="15.02.2017" style={{outline: "none", cursor: "inherit"}}>PLAY VIDEO</time>
                                <span className="portfolio-item-more"><i className="ion-ios-arrow-right"></i></span>
                            </div>
                            <header className="portfolio-header">
                                <div className="portfolio-item-title">
                                    <div className="meta-categories" style={{outline: "none", cursor: "inherit"}}>AT&T</div>
                                    <h3 style={{outline: "none", cursor: "inherit"}}>Food truck love</h3>
                                </div>
                                <figure className="portfolio-img"><img src="images/project-img5.jpg" alt="portfolio item 5" className="featured-image-v2" style={{outline: "none", cursor: "inherit"}} /></figure>
                            </header>
                        </a>
                    </div>

                </div> 
            </section>
            
    </div>
  );
}

export default Work;


