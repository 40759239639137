import '../App.css';

function Triciclo(props) {
  return (
    
      
          
          <div id="triciclo-gif" className="triciclo">
      
        {/* <img id="triciclo-gif" src="/images/triciclo.svg" className="triciclo" /> */}
          <svg className="triciclo-svg" xmlns="http://www.w3.org/2000/svg" width="142.815" height="133.631" viewBox="0 0 142.815 133.631">
  <g id="Group_320" data-name="Group 320" transform="translate(-526.916 -293.712)">
    <g id="Group_318" data-name="Group 318"> 
      {/* <g id="Group_316" data-name="Group 316"> */}
        {/* <g>
        <rect id="Rectangle_5" data-name="Rectangle 5" width="0.656" height="53.405" transform="translate(609.594 394.237) rotate(-90)" fill="#959595"/>
        <rect id="Rectangle_6" data-name="Rectangle 6" width="0.656" height="53.405" transform="translate(616.008 376.545) rotate(-48.738)" fill="#959595"/>
        <rect id="Rectangle_7" data-name="Rectangle 7" width="0.656" height="53.405" transform="translate(616.441 411.767) rotate(-131.263)" fill="#959595"/>
        <rect id="Rectangle_8" data-name="Rectangle 8" width="0.656" height="53.405" transform="translate(635.969 367.206)" fill="#959595"/>
        </g>
        
      
      <g id="Group-second-wheel">
      <rect id="Rectangle_4" data-name="Rectangle 4" width="0.656" height="36.04" transform="translate(547.49 387.481)" fill="#959595"/>
      <rect id="Rectangle_9" data-name="Rectangle 9" width="0.656" height="35.884" transform="translate(529.425 405.781) rotate(-90)" fill="#959595"/>
      <rect id="Rectangle_10" data-name="Rectangle 10" width="0.656" height="36.369" transform="translate(534.758 394.428) rotate(-48.729)" fill="#959595"/>
      <rect id="Rectangle_11" data-name="Rectangle 11" width="0.656" height="37.171" transform="translate(533.967 418.046) rotate(-131.271)" fill="#959595"/>
      </g>
      <path id="Path_24" data-name="Path 24" d="M602.863,393.909A33.434,33.434,0,1,1,636.3,427.343,33.473,33.473,0,0,1,602.863,393.909Zm6.821,0A26.612,26.612,0,1,0,636.3,367.3,26.643,26.643,0,0,0,609.684,393.909Z" fill="#fff"/>
      <path id="Path_25" data-name="Path 25" d="M526.916,406.03a20.9,20.9,0,1,1,20.9,20.9A20.926,20.926,0,0,1,526.916,406.03Zm6.821,0a14.081,14.081,0,1,0,14.081-14.081A14.1,14.1,0,0,0,533.737,406.03Z" fill="#fff"/> */}
      <rect id="Rectangle_12" data-name="Rectangle 12" width="3.42" height="18.274" transform="matrix(-0.998, 0.066, -0.066, -0.998, 634.854, 343.081)" fill="#f9c016"/>
      <path id="Path_26" data-name="Path 26" d="M627.682,298.931a14.042,14.042,0,0,1,2.886,1.75c6.066,4.771,8.876,15.346,4.548,20.552a8.928,8.928,0,0,1-4.183,2.72s-3.314,1.548-8.064-.274c-7.039-2.7-6.739-12.073-15.317-17.463a20.348,20.348,0,0,0-5.144-2.3l-.438,2.274a11.516,11.516,0,0,1,2.99.825c2.27.977,3.826,2.541,5.931,5.385a60.979,60.979,0,0,1,5.684,9.358,12.433,12.433,0,0,0,4.065,3.605c5.6,3.058,11.625.456,12.514.055a9.839,9.839,0,0,0,3.226-2.132c5.082-5.1,2.254-14.141,2.06-14.734a19.9,19.9,0,0,0-1.75-4.2,18.88,18.88,0,0,0-3.148-4.2,17.248,17.248,0,0,0-4.11-2.974Z" fill="#bbbdbf" fill-rule="evenodd"/>
      <rect id="Rectangle_13" data-name="Rectangle 13" width="4.723" height="26.155" transform="translate(570.574 370.952) rotate(180)" fill="#bbbdbf"/>
      <rect id="Rectangle_14" data-name="Rectangle 14" width="1.421" height="26.155" transform="translate(567.272 370.952) rotate(180)" fill="#bbbdbf"/>
      <path id="Path_27" data-name="Path 27" d="M550.4,403.917s14.731-46.264,75.865-54.636l7.244-1.173L631.336,342s-33.75,4.2-51.639,17.735c0,0-23.477,12.646-34.146,41.094Z" fill="#f9c016" fill-rule="evenodd"/>
      <path id="Path_28" data-name="Path 28" d="M546.953,343.556c1.121,4.659,17.711,4.132,21.289,4.132,11.758,0,21.29-1.85,21.29-4.132,0-1.472-7.861-.159-21.29-4.132-7.178-2.124-11.2-4.349-15.779-2.362C549.514,338.342,546.417,341.329,546.953,343.556Z" fill="#01b08d" fill-rule="evenodd"/>
      <path id="Path_29" data-name="Path 29" d="M548.575,342.873c3.314,2.011,6.5,2.26,10.8,2.5,5.769.318,8.654.477,10.845.437a103.044,103.044,0,0,0,12.133-.946,1.408,1.408,0,0,0,.932-.1c1.492-.176,3.953-.612,3.124-.472a14.63,14.63,0,0,0,3.081-.889.37.37,0,0,1,.038.155c0,2.282-9.532,4.132-21.29,4.132-3.578,0-20.168.527-21.289-4.132a2.775,2.775,0,0,1,.183-1.669A15.88,15.88,0,0,0,548.575,342.873Z" fill="#026e59" fill-rule="evenodd"/>
      <rect id="Rectangle_15" data-name="Rectangle 15" width="4.779" height="1.645" transform="translate(634.876 343.308) rotate(175.954)" fill="#f29620"/>
      <path id="Path_30" data-name="Path 30" d="M553.169,391.069q1.833-3.115,3.854-6.115c.717-1.06,3.123-4.336,3.252-4.492a144.191,144.191,0,0,1,10.554-10.535c1.057-.731,3.005-2.463,5.723-4.378a104.031,104.031,0,0,1,19.777-10.733,106.489,106.489,0,0,1,11.807-4.46,117.246,117.246,0,0,1,12.987-3.149c1.078-.194,3.236-.564,6.822-1.18,2.266-.389,4.131-.707,5.378-.919l.189,3-7.244,1.173c-61.134,8.372-75.865,54.636-75.865,54.636l-2.991-1.9C549.181,398.288,551.077,394.624,553.169,391.069Z" fill="#f29620" fill-rule="evenodd"/>
      <rect id="Rectangle_16" data-name="Rectangle 16" width="4.761" height="52.606" transform="matrix(-0.998, 0.068, -0.068, -0.998, 638.436, 395.2)" fill="#f9c016"/>
      <rect id="Rectangle_17" data-name="Rectangle 17" width="5.737" height="2.525" transform="matrix(-0.996, 0.09, -0.09, -0.996, 635.582, 341.596)" fill="#fbe900"/>
      <rect id="Rectangle_18" data-name="Rectangle 18" width="1.633" height="51.108" transform="translate(635.12 392.966) rotate(176.075)" fill="#f29620"/>
      <circle id="Ellipse_261" data-name="Ellipse 261" cx="5.772" cy="5.772" r="5.772" transform="translate(630.525 388.137)" fill="#010101"/>
      <g id="Group_317" data-name="Group 317">
        <rect id="Rectangle_19" data-name="Rectangle 19" width="14.299" height="4.723" transform="matrix(-0.999, -0.051, 0.051, -0.999, 603.179, 307.033)" fill="#fff"/>
        <rect id="Rectangle_20" data-name="Rectangle 20" width="14.299" height="1.837" transform="matrix(-0.999, -0.051, 0.051, -0.999, 603.179, 307.033)" fill="#fff"/>
      </g>
      <path id="Path_31" data-name="Path 31" d="M542.046,405.453a5.772,5.772,0,1,0,5.772-5.772A5.773,5.773,0,0,0,542.046,405.453Z" fill="#010101" fill-rule="evenodd"/>
    </g>
    <path id="Path_32" data-name="Path 32" d="M632.883,299.953a15.3,15.3,0,0,0,1.7,2.425,12.333,12.333,0,0,0,1.674,2.089,5.7,5.7,0,0,0-1.16-2.375A6.284,6.284,0,0,0,632.883,299.953Z" fill="#bbbdbf" fill-rule="evenodd"/>
    <g id="Group_319" data-name="Group 319">
      <rect id="Rectangle_21" data-name="Rectangle 21" width="12.457" height="4.114" transform="matrix(-0.972, -0.236, 0.236, -0.972, 629.51, 300.653)" fill="#fff"/>
      <rect id="Rectangle_22" data-name="Rectangle 22" width="12.457" height="1.601" transform="matrix(-0.972, -0.236, 0.236, -0.972, 629.51, 300.653)" fill="#fff"/>
    </g>
  </g>
</svg>

<svg id="rueda" className="rueda" xmlns="http://www.w3.org/2000/svg" width="41.804" height="41.805" viewBox="0 0 41.804 41.805">
  <g id="Group_925" data-name="Group 925" transform="translate(-602.916 -384.965)">
    <rect id="Rectangle_4" data-name="Rectangle 4" width="0.656" height="36.04" transform="translate(623.49 387.319)" fill="#959595"/>
    <rect id="Rectangle_9" data-name="Rectangle 9" width="0.656" height="35.884" transform="translate(605.425 405.619) rotate(-90)" fill="#959595"/>
    <rect id="Rectangle_10" data-name="Rectangle 10" width="0.656" height="36.369" transform="translate(610.758 394.265) rotate(-48.729)" fill="#959595"/>
    <rect id="Rectangle_11" data-name="Rectangle 11" width="0.656" height="37.171" transform="translate(609.967 417.883) rotate(-131.271)" fill="#959595"/>
    <path id="Path_25" data-name="Path 25" d="M526.916,406.03a20.9,20.9,0,1,1,20.9,20.9A20.926,20.926,0,0,1,526.916,406.03Zm6.821,0a14.081,14.081,0,1,0,14.081-14.081A14.1,14.1,0,0,0,533.737,406.03Z" transform="translate(76 -0.162)" fill="#fff"/>
    <path id="Path_31" data-name="Path 31" d="M542.046,405.453a5.772,5.772,0,1,0,5.772-5.772A5.773,5.773,0,0,0,542.046,405.453Z" transform="translate(76 -0.162)" fill="#010101" fill-rule="evenodd"/>
  </g>
</svg>

<svg id="rueda-grande" className="rueda-grande" xmlns="http://www.w3.org/2000/svg" width="66.868" height="66.868" viewBox="0 0 66.868 66.868">
  <g id="Group_924" data-name="Group 924" transform="translate(-678.863 -360.313)">
    <g id="Group_316" data-name="Group 316" transform="translate(76 -0.162)">
      <rect id="Rectangle_5" data-name="Rectangle 5" width="0.656" height="53.405" transform="translate(609.594 394.237) rotate(-90)" fill="#959595"/>
      <rect id="Rectangle_6" data-name="Rectangle 6" width="0.656" height="53.405" transform="translate(616.008 376.545) rotate(-48.738)" fill="#959595"/>
      <rect id="Rectangle_7" data-name="Rectangle 7" width="0.656" height="53.405" transform="translate(616.441 411.767) rotate(-131.263)" fill="#959595"/>
      <rect id="Rectangle_8" data-name="Rectangle 8" width="0.656" height="53.405" transform="translate(635.969 367.206)" fill="#959595"/>
    </g>
    <path id="Path_24" data-name="Path 24" d="M602.863,393.909A33.434,33.434,0,1,1,636.3,427.343,33.473,33.473,0,0,1,602.863,393.909Zm6.821,0A26.612,26.612,0,1,0,636.3,367.3,26.643,26.643,0,0,0,609.684,393.909Z" transform="translate(76 -0.162)" fill="#fff"/>
    <circle id="Ellipse_261" data-name="Ellipse 261" cx="5.772" cy="5.772" r="5.772" transform="translate(706.525 387.975)" fill="#010101"/>
  </g>
</svg>


         
    
    </div>
  );
}

export default Triciclo;