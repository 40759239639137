import '../App.css';
import { useState } from 'react';

function Close(props) {

     function handleClick(e) {
    e.preventDefault();

    let header = document.getElementById('headerTag');
    let mt = document.getElementById('mt-44');
    let about = document.getElementById('about');
    let work = document.getElementById('work');
    let awards = document.getElementById('awards');
    let clients = document.getElementById('clients-sec');
    let icomBanner = document.getElementById('icom-banner');
    let services = document.getElementById('services');
    let lihNews = document.getElementById('lih-news');
    let contact = document.getElementById('contacts');
    let footer = document.getElementById('footer');
    let body = document.getElementById('body-color');
    let timelineParent = document.getElementById('timeline-parent');
    let timelineImg = document.getElementById('timeline-img');
    
    body.classList.remove("bg-grey");
    header.classList.remove("display-none");
    mt.classList.remove("display-none");
    about.classList.remove("display-none");
    work.classList.remove("display-none");
    awards.classList.remove("display-none");
    clients.classList.remove("display-none");
    icomBanner.classList.remove("display-none");
    services.classList.remove("display-none");
    lihNews.classList.remove("display-none");
    contact.classList.remove("display-none");
    footer.classList.remove("display-none");
    timelineImg.classList.remove("display-none");
    timelineParent.classList.remove("display--opacity-1");
    
    
  }
  


  return (
    <div id="close-btn" className="display-none close-btn">
      <p style={{fontSize: "35px"}} onClick={handleClick}>x</p>
    </div>
    
   
  );
}

export default Close;