import '../../App.css';
import { useState } from 'react';

function Clients(props) {

  return (
    <div id="clients-sec">
         <section id="clients carrrusel" className="m-t-5 m-b-5">
        <div className="wrapper--large--auto">

                <h2 className="text-center m-b-3" data-scrolled-into-view="true">Who rides with us?</h2>
                <p className="text-center m-b-4--desktop-only ps-4 pe-4" data-scrolled-into-view="true">We are partners to local and global brands, and believe in long-lasting relationships.</p>
           
                <div className="items-carrusel" data-scrolled-into-view="true">
                    
                    <div>
                        <img src="images/logos/clientes/logo_directtv.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_nissan.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_oriental.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_jetblue.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_sp.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_nuc.png" />
                    </div> 
                    <div>
                        <img src="images/logos/clientes/logo_universal.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_pg.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_ge.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_rooms.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_united.png" />
                    </div> 
                    <div>
                        <img src="images/logos/clientes/logo_sociedad.png" />
                    </div> 
                    <div>
                        <img src="images/logos/clientes/logo_rd.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_suiza.png" />
                    </div>
                    <div>
                        <img src="images/logos/clientes/logo_voces.png" />
                    </div> 
                    <div>
                        <img src="images/logos/clientes/logo_mmm.png" />
                    </div> 
                    <div>
                        <img src="images/logos/clientes/logo_hornodepane.png" />
                    </div> 
                    <div>
                        <img src="images/logos/clientes/logo_microsoft.png" />
                    </div> 
                </div>   
        </div>
    </section>
    </div>
  );
}

export default Clients;


