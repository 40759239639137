import '../App.css';
import HorizontalScroll from 'react-scroll-horizontal';
import Triciclo from './Triciclo';
import Garage from './Garage';
import Story from "./Story";
import Close from "./Close";
import data from "../data.json";


function Timeline(props) {

  // console.log(data[0].year);
  // console.log(typeof(data[0].description));


  let deltaY, deltaX;
function changeGif(e) {
    deltaY = e.deltaY;
    deltaX = e.deltaX;
    // console.log('horizontal left-right ' + ' - ' + e.deltaX); //horizontal left-right
    // console.log('vertical up-down ' + ' - ' +e.deltaY); //vertical up-down

    scrollRotate();
    
    if(deltaY) {
     console.log('from Y');
     scrollRotate();
    }
    if(deltaX) {
      console.log('from X');
      scrollXRotate();
    }
    let img = document.getElementById('triciclo-gif');

    if(e.deltaX <= -2 || e.deltaY <= -2) {
      // console.log('menor o igual de -2 - (hacia la derecha)');
      img = document.getElementById('triciclo-gif');
      img.classList.remove("flip-horizontal");
      

    }

    if(e.deltaX >= 2 || e.deltaY >= 2) {
      // console.log('mayor o igual de 2 - (hacia la izquierda)');
      img = document.getElementById('triciclo-gif');
      img.classList.add("flip-horizontal");
      

    }


}

  let scrollRotate = function() {
    // console.log(deltaY);
    let rueda = document.getElementById("rueda");
    let ruedaGrande = document.getElementById("rueda-grande");
    rueda.style.transform = "rotate(" + deltaY + "grad)";
    ruedaGrande.style.transform = "rotate(" + deltaY + "grad)";
    
  }

  let scrollXRotate = function() {
    // console.log(deltaY);
    let rueda = document.getElementById("rueda");
    let ruedaGrande = document.getElementById("rueda-grande");
    rueda.style.transform = "rotate(" + deltaX + "grad)";
    ruedaGrande.style.transform = "rotate(" + deltaX + "grad)";
  }

  let startVal;
  let moveVal;

  function touchStart(e) {
    startVal = e.touches[0].clientX;
    // console.log('start: ' + startVal);
    
  }

  function touchMove(e) {
    moveVal = e.touches[0].clientX;
    // console.log('move: ' + moveVal);

    let rueda = document.getElementById("rueda");
    let ruedaGrande = document.getElementById("rueda-grande");
    rueda.style.transform = "rotate(" + moveVal + "rad)";
    ruedaGrande.style.transform = "rotate(" + moveVal + "rad)";
    
    
  }

  function touchEnd(e) {
   
    let rueda = document.getElementById("rueda");
    let ruedaGrande = document.getElementById("rueda-grande");
    rueda.style.transform = "rotate(" + moveVal + "grad)";
    let totalVal = moveVal * 1;
    ruedaGrande.style.transform = "rotate(" + totalVal + "grad)";
    
    
    // console.log('End' + ': ' + startVal + ' - ' + moveVal);

      if(startVal) {
        if(startVal > moveVal) {
          // console.log('derecha');
          let img = document.getElementById('triciclo-gif');
          img.classList.remove("flip-horizontal");
        }
  
       if(startVal < moveVal) {
        //  console.log('izquierda');
         let img = document.getElementById('triciclo-gif');
         img.classList.add("flip-horizontal");
        }

        
      
    }
    
  }
    



  return (
    <div id="timeline-parent" className="timeline-parent " onWheel={(e) => changeGif(e)} onTouchStart={(e) => touchStart(e)} onTouchMove={(e) => touchMove(e)} onTouchEnd={(e) => touchEnd(e)}>
      <p style={{color: "#ffffff", paddingTop: "109px"}}>Scroll or swipe left to right</p>
    <Close />
    <Triciclo />
    <HorizontalScroll className="timeline">
      <Garage type="" alignment="grage-image" gapAlignment="left" gapPhoto="" gapSize="10"  photo="/images/stories/casita_lih.png"/>
      <Story type="" alignment="bottom-right-pintype-down" gapAlignment="left" gapPhoto="" gapSize="10"  photo="/images/stories/pin-type-down/pin_down_1.png" storyTextType="story-text--down-pin-type" storyYearStyle="yearStyle--up" textYear={data[0].year} textDescription={data[0].description} textSmallType="story-text--small-down-y"/>
      <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_1.png" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type" textYear={data[1].year} textDescription={data[1].description} textSmallType="story-text--small-up"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_1.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[2].year} textDescription={data[2].description} textSmallType="story-text--small-down"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_2.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type" textYear={data[3].year} textDescription={data[3].description} textSmallType="story-text--small-up"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_2.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[4].year} textDescription={data[4].description} textSmallType="story-text--small-down"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_3.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[5].year} textDescription={data[5].description} textSmallType="story-text--small-down"/>
       <Story type="" alignment="top-right-pintype-up" photo="/images/stories/pin-type-up/pin_up_1.png" gapSize="10" gapAlignment="left" storyTextType="story-text--up-pin-type" textYear={data[6].year} textDescription={data[6].description} textSmallType="story-text--small-up-y"/>
       <Story type="" alignment="bottom-right-pintype-down" photo="/images/stories/pin-type-down/pin_down_2.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-pin-type" textYear={data[7].year} textDescription={data[7].description} textSmallType="story-text--small-down-y"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_3.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type" textYear={data[8].year} textDescription={data[8].description} textSmallType="story-text--small-up custom-text--1"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_4.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type yearStyle--up" textYear={data[9].year} storyYearStyle="yearStyle--up" textDescription={data[9].description} textSmallType="story-text--small-up" />
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_4.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[10].year} textDescription={data[10].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story type="" alignment="top-right-pintype-up" photo="/images/stories/pin-type-up/pin_up_2.png" gapSize="10" gapAlignment="left" storyTextType="story-text--up-pin-type" textYear={data[11].year} textDescription={data[11].description} textSmallType="story-text--small-up-y" />
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_5.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[12].year} textDescription={data[12].description} textSmallType="story-text--small-down" />
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_6.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[13].year} textDescription={data[13].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_5.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type yearStyle--up" textYear={data[14].year} textDescription={data[14].description} textSmallType="story-text--small-up"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_7.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[15].year} textDescription={data[15].description} textSmallType="story-text--small-down"/>
       <Story type="" alignment="top-right-pintype-up" photo="/images/stories/pin-type-up/pin_up_3.png" gapSize="10" gapAlignment="left" storyTextType="story-text--up-pin-type" textYear={data[16].year} textDescription={data[16].description} textSmallType="story-text--small-up-y"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_8.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[17].year} textDescription={data[17].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story type="" alignment="top-right-pintype-up" photo="/images/stories/pin-type-up/pin_up_4.png" gapSize="10" gapAlignment="left" storyTextType="story-text--up-pin-type" textYear={data[18].year} textDescription={data[18].description} textSmallType="story-text--small-up-y custom-text--3"/>
       <Story type="" alignment="bottom-right-garita" photo="/images/stories/dots-type-down/dots_down_9.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[19].year} textDescription={data[19].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_10.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[20].year} textDescription={data[20].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_6.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type yearStyle--up" textYear={data[21].year} textDescription={data[21].description} textSmallType="story-text--small-up-y custom-text--2"/>
       <Story type="" alignment="top-right-pintype-up-b" photo="/images/stories/pin-type-up/pin_up_5.png" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type yearStyle--up-custom-pin" textYear={data[22].year} textDescription={data[22].description} textSmallType="story-text--small-up-custom-pin"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_11.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[23].year} textDescription={data[23].description} textSmallType="story-text--small-down"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_7.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type yearStyle--up" textYear={data[24].year} textDescription={data[24].description} textSmallType="story-text--small-up"/>
       <Story type="" alignment="bottom-right-pintype-down" photo="/images/stories/pin-type-down/pin_down_3.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-pin-type" textYear={data[25].year} textDescription={data[25].description} textSmallType="story-text--small-down-y"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_8.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type yearStyle--up" textYear={data[26].year} textDescription={data[26].description} textSmallType="story-text--small-up"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_12.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[27].year} textDescription={data[27].description} textSmallType="story-text--small-down-y custom-text--2"/> 
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_13.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[28].year} textDescription={data[28].description} textSmallType="story-text--small-down"/>
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_9.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type" textYear={data[29].year} textDescription={data[29].description} textSmallType="story-text--small-up"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_14.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[30].year} textDescription={data[30].description} textSmallType="story-text--small-down-y custom-text--2"/> 
       <Story  type="" alignment="top-right" photo="/images/stories/dots-type-up/dots_up_10.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type yearStyle--up" textYear={data[31].year} textDescription={data[31].description} textSmallType="story-text--small-up"/>
       <Story type="" alignment="bottom-right-pintype-down" photo="/images/stories/pin-type-down/pin_down_4.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-pin-type" textYear={data[32].year} textDescription={data[32].description} textSmallType="story-text--small-down custom-text--4"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_16.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[33].year} textDescription={data[33].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_17.png" gapSize="10" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[34].year} textDescription={data[34].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story  type="" alignment="top-right-b" photo="/images/stories/dots-type-up/dots_up_11.png" text="" gapSize="10" gapAlignment="left" storyTextType="story-text--up-dots-type" textYear={data[35].year} textDescription={data[35].description} textSmallType="story-text--small-up custom-text--5"/>
       <Story type="" alignment="bottom-right" photo="/images/stories/dots-type-down/dots_down_18.png" gapSize="4" gapAlignment="left" storyTextType="story-text--down-dots-type" textYear={data[36].year} textDescription={data[36].description} textSmallType="story-text--small-down-y custom-text--2"/>
       <Story id="50-years-img" type="" alignment="end-image--bottom-right-50" photo="/images/stories/dots-type-down/50-img.png" gapSize="0" gapAlignment="left" gtmEvent="true"/>
       <div className="test1"></div>
      </HorizontalScroll>
    
    </div>
    
    
  );
}

export default Timeline;



