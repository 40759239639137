import '../../App.css';
import { useState } from 'react';

function About(props) {

  return (
    <div>
        <section id="about" className="m-t-5 m-b-5 p-t-4 p-b-4">
        <div className="wrapper--large">
            <div className="d-flex justify-content-center" data-scrolled-into-view="true">
                <h2>What powers our ride</h2>
            </div>

            <div className="d-flex justify-content-around flex-wrap p-3">
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                    <div className="icon-container">
                      <img src="images/icons/brain_icon.png" width="100%"/> 
                    </div>
                    <h4 className="bold-black text-center">Creative drive</h4>
                    <p className="grey text-center">Integrated campaigns that create buzz and make your brand more relevant.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                    <div className="icon-container">
                      <img src="images/icons/digital_hands_icon.png" width="100%"/>
                    </div>
                    <h4 className="bold-black text-center">Ahead in the digital shift</h4>
                    <p className="grey text-center">
                        We are partners in taking
                        your brand into the next decade, keeping abreast of digital trends,
                        best practices and creative solutions.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                  <div className="icon-container">
                      <img src="images/icons/table_icon.png" width="100%"/>
                    </div>
            
                    <h4 className="bold-black text-center">Media buying on turbo</h4>
                    <p className="grey text-center">
                        Your brand is supported
                        by the largest media buying agency in Puerto Rico for the most effective buys and first access to
                        media opportunities.</p>
                </div>        
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img src="images/icons/graphic_icon.png" width="100%"/>
                    </div>
                    <h4 className="bold-black text-center">Measurable results</h4>
                    <p className="grey text-center">Brand-building strategies with measurable results optimize your
                        brand’s health.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img src="images/icons/heart_icon.png" width="100%"/>
                    </div>
                    <h4 className="bold-black text-center">Integrated team thinking</h4>
                    <p className="grey text-center">Professionals from diverse areas of expertise bring more ideas
                        to the table.</p>
                </div>
                <div className="flex-basis--300 d-flex flex-column align-items-center m-4" data-scrolled-into-view="true">
                <div className="icon-container">
                      <img src="images/icons/rocket_icon.png" width="100%"/>
                    </div>
                    <h4 className="bold-black text-center">The joy ride</h4>
                    <p className="grey text-center">We love to play. To experiment. Passionate people
                        with a strong work ethic give more power to your brand.</p>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default About;


