import '../App.css';

function Gap(props) {
  let gapSteps = [], i = 0;
  for(i = 0; i < props.gapSize; i++){
    gapSteps.push( (<div className="step" key={"step" + i}></div>) );
  }

  return (
    <div className="gap-item">
      {props.gapPhoto  && <div className="gap-item--image-container"><img src={props.gapPhoto} className="gap-item--photo " /></div> }
      {gapSteps}
    </div>
  );
}

export default Gap;
