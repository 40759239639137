import '../../App.css';
import { useState } from 'react';

function IcomBanner(props) {

    return (
        <div>
            <section id="icom-banner" className="m-b-4 section-bg--grey">
                <div className="wrapper--large">
                    <div className="section-bg pt-5 pb-5 ps-2 pe-2" data-scrolled-into-view="true">
                        <div className="grid-container">
                            <div class="grid-items">
                                <img className=" img-icom p-4" src="images/logo_icom.png" />
                                <p className="w-100 caption-black--light p-2 fs-14" style={{ textAlign: "left" }}>Our working relationship with the ICOM network gives us access to the world’s largest network of independent advertising and marketing communications agencies, spanning more than 65 countries and covering 95% of the world’s markets.</p>
                            </div>
                            <div class="grid-items">
                                <img className="img-icom p-4" src="images/women-leader-logo.png" />
                                <p className="w-100 caption-black--light p-2 fs-14" style={{ textAlign: "left" }}>We are the only agency in Puerto Rico to be W Certified for three years. Our workforce is over 65% female.</p>
                            </div>
                            <div class="grid-items">
                                <img className="img-icom p-4" src="images/IPRN-FINAL_170.png" />
                                <p className="w-100 caption-black--light p-2 fs-14" style={{ textAlign: "left" }}>International network of independent public relations agencies in over 100 cities worldwide.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default IcomBanner;


